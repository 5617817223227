import { buildFileUrl, getFile } from "@sanity/asset-utils";
import sanityConfig from "services/sanity-config";

const getFilePath = (node) => {
  if (!node) return;

  const config = sanityConfig();
  return buildFileUrl(getFile(node, config).asset, config);
};

export default getFilePath;
